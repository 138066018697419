@tailwind base;
@tailwind components;
@tailwind utilities;

.cdx-marker {
  background-color: #6b8c74;
  padding: 2px;
  color: #fffce8;
}

#article-page a {
  text-decoration: underline;
  font-weight: bold;
  color: #34543d;
}
#article-page a:hover {
  opacity: 0.8;
}

/* EASTER EGG SPECIAL THEME RED */
#special-theme .bg-green,
#special-theme .cdx-marker,
#special-theme .bg-light-green,
#special-theme .hover\:bg-green:hover {
  background-color: rgb(251 113 133) !important;
}
#special-theme .border-green {
  border-color: rgb(251 113 133) !important;
}
#special-theme .text-green,
#special-theme #article-page a,
#special-theme .text-light-green {
  color: rgb(251 113 133) !important;
}

/* HIDE CAPTCHA BADGE */
.grecaptcha-badge {
  visibility: hidden !important;
}
